import React from 'react';
import { SiWhatsapp } from 'react-icons/si';

import { Container } from './styles';

import logo from '~/assets/logos/logo.png';

const Footer: React.FC = () => {
  return (
    <Container className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-5">
            <img src={logo} alt="logo" className="d-block mx-auto" />
          </div>
          <div className="col-11 col-md-12 mb-3">
            <hr />
          </div>
          <div className="col-11 col-md-12 d-flex flex-column flex-lg-row justify-content-between pb-3 pb-lg-0">
            <small className="text-center text-lg-start">
              © 2023 Todos os direitos reservados a{' '}
              <b>DUO ULTIMATE PROJETOS E ORCAMENTOS BIM LTDA.</b>
            </small>
            <small className="text-center text-lg-start mt-3 mt-lg-0">
              Desenvolvido por:{' '}
              <a
                href="https://devsigner.com.br/"
                target="_blank"
                className="underline"
              >
                <b>Devsigner</b>
              </a>
            </small>
          </div>
        </div>
      </div>
      <a
        href="https://api.whatsapp.com/send?phone=5581982306464&text=Ol%C3%A1,%20estou%20vindo%20do%20site%20da%20Duo%20Ultimate%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es"
        target="_blank"
        rel="noreferrer"
        className="btn-whatsapp d-flex align-items-center justify-content-center"
      >
        <SiWhatsapp size={33.88} color="#fff" />
      </a>
    </Container>
  );
};

export default Footer;
