import styled, { css } from 'styled-components';

interface IMenuProps {
  active: boolean;
}

export const Container = styled.header`
  background: transparent;
  width: 100%;
  height: 79px;

  .menu-fix {
    height: 79px;
    background: #2e2e2e;
    border-bottom: 1px solid #626262;
    box-shadow: 0px 11px 11px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    z-index: 1000;
  }
`;
export const Menu = styled.div<IMenuProps>`
  > button {
    width: 30px;
    height: 30px;

    span {
      display: block;
      width: 100%;
      height: 4px;
      background-color: #9a9a9a;
      border-radius: 30px;
      transition-duration: 0.5s;

      :nth-child(2) {
        margin: 6px auto 6px 0px;
      }

      ${(props) =>
        props.active &&
        css`
          :nth-child(1) {
            transform: rotate(225deg);
          }

          :nth-child(2) {
            opacity: 0;
            transform: rotate(225deg);
          }

          :nth-child(3) {
            transform: rotate(315deg);
            margin-top: -20px;
          }
        `}
    }
  }

  .menu-group {
    width: 100%;
    height: calc(100vh - 79px);
    background: #202020;
    transition-duration: 0.4s;
    top: 79px;
    left: ${(props) => (props.active ? '0' : '1000%')};
    position: absolute;

    .menu {
      .btn-menu {
        transition-duration: 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: transparent;
        -webkit-text-fill-color: #fff;
        position: relative;
        line-height: 30px;

        :after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          background: transparent;
          left: 50%;
          transform: translateX(-50%);
          bottom: -10px;
          border-radius: 50%;
          transition-duration: 0.3s;
        }

        + .btn-menu {
          margin-top: 60px;
        }
      }

      .btn-menu:hover,
      .btn-menu.active {
        -webkit-text-stroke-color: #fff;

        :after {
          background: #cfb071;
        }
      }

      .link-menu {
        -webkit-text-fill-color: #cfb071;

        ::after {
          content: unset;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .menu-group {
      background: transparent;
      position: relative;
      height: 79px;
      top: unset;
      left: unset;

      .menu {
        .btn-menu,
        .btn-menu + .btn-menu {
          margin-top: unset;
        }
      }
    }
  }
`;
