import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { Container, Menu } from './styles';

import logo_short from '~/assets/logos/logo-short.svg';

const Header: React.FC = () => {
  const location = useLocation();
  const [selected, setSelected] = useState('home');
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(false);
  }, [location, selected]);

  return (
    <>
      <Container className="menu-header">
        <div className="container-fluid py-3 py-lg-0 menu-fix">
          <div className="row align-items-center h-100">
            <div className="col-12">
              <div className="container">
                <div className="row">
                  <div className="col-12 d-flex justify-content-between align-items-center text-center">
                    <div>
                      <Link to="/">
                        <img src={logo_short} alt="logo" />
                      </Link>
                    </div>
                    <Menu active={active}>
                      <button
                        type="button"
                        className="bg-transparent d-lg-none border-0 mx-3"
                        onClick={() => setActive(!active)}
                      >
                        <span />
                        <span />
                        <span />
                      </button>
                      <div className="menu-group active">
                        <div className="menu d-flex flex-column flex-lg-row justify-content-center align-items-lg-center h-100">
                          <AnchorLink
                            href="#home"
                            offset={400}
                            onClick={() => setSelected('home')}
                            className={`btn-menu h5 mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center ${
                              selected === 'home' ? 'active' : ''
                            }`}
                          >
                            Home
                          </AnchorLink>
                          <AnchorLink
                            href="#quem-somos"
                            offset={75}
                            onClick={() => setSelected('quem-somos')}
                            className={`btn-menu h5 mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center ${
                              selected === 'quem-somos' ? 'active' : ''
                            }`}
                          >
                            Quem somos
                          </AnchorLink>
                          <AnchorLink
                            href="#nossas-solucoes"
                            offset={60}
                            onClick={() => setSelected('nossas-solucoes')}
                            className={`btn-menu h5 mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center ${
                              selected === 'nossas-solucoes' ? 'active' : ''
                            }`}
                          >
                            Nossas soluções
                          </AnchorLink>
                          <AnchorLink
                            href="#contato"
                            offset={60}
                            onClick={() => setSelected('contato')}
                            className={`btn-menu h5 mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center ${
                              selected === 'contato' ? 'active' : ''
                            }`}
                          >
                            Contato
                          </AnchorLink>
                          <a
                            href="https://api.whatsapp.com/send?phone=5581982306464&text=Ol%C3%A1,%20estou%20vindo%20do%20site%20da%20Duo%20Ultimate%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es"
                            target="_blank"
                            rel="noreferrer"
                            className="btn-menu link-menu h5 mb-4 mb-lg-0 mx-4 d-flex justify-content-center align-items-center"
                          >
                            Solicite uma proposta
                          </a>
                        </div>
                      </div>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Header;
