import React from 'react';
import Lottie from 'react-lottie';

import { Container } from './styles';

import loading from '~/assets/animations/loading.json';

interface ILoading {
  show: boolean;
}

const Loading: React.FC<ILoading> = ({ show }) => {
  return (
    <Container
      show={show}
      className="d-flex align-items-center justify-content-center"
    >
      <Lottie
        options={{
          animationData: loading,
          autoplay: true,
          loop: true,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={165}
        width={198}
      />
    </Container>
  );
};

export default Loading;
