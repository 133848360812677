import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  background: #010101;

  hr {
    color: #464646;
    opacity: 1;
  }

  small,
  b {
    color: #e4e4e4;
  }

  .btn-whatsapp {
    background: #d6ad5a;
    box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
    height: 67px;
    width: 67px;
    border-radius: 50%;
    position: fixed;
    bottom: 10px;
    right: 10px;
    transition-duration: 0.3s;

    :hover {
      background: ${darken(0.1, '#d6ad5a')};
    }
  }

  @media screen and (min-width: 992px) {
    .btn-whatsapp {
      height: 89px;
      width: 89px;

      svg {
        width: 45px;
        height: 45px;
      }
    }
  }
`;
