import styled from 'styled-components';

import bannerMobile from '~/assets/banners/hero-section-mobile.png';
import bannerDesktop from '~/assets/banners/hero-section.png';
import poolArea from '~/assets/defaults/pool-area.png';
import poolGourmetArea from '~/assets/defaults/pool-gourmet-area.png';
import gourmetArea from '~/assets/defaults/gourmet-area.png';

export const Container = styled.div`
  div.rooms {
    > div {
      width: 94.66px;
      height: 210.03px;
      background-color: #2e2e2e;
      border-radius: 16px;
      position: relative;
    }

    .room-1 {
      background: url(${poolArea});
      background-size: cover;
      background-position: center;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .room-2 {
      background: url(${poolGourmetArea});
      background-size: cover;
      background-position: center;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .room-3 {
      background: url(${gourmetArea});
      background-size: cover;
      background-position: center;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .room-1,
    .room-3 {
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 39.76px;
        height: 39.76px;
        background: #323232;
        box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      }
    }

    .room-1 {
      span {
        position: absolute;
        left: 2px;
        bottom: -13px;
      }
    }

    .room-3 {
      span {
        position: absolute;
        right: 15px;
        top: -10px;
      }
    }
  }

  div.tag {
    background: #cfb071;

    p {
      color: #654b16;
    }
  }

  div.tabs {
    transition-duration: 0.3s;
    overflow: auto;

    > div {
      width: max-content;

      button:first-child {
        border-radius: 24px 24px 0px 0px;
      }

      button:last-child {
        border-radius: 0px 0px 24px 24px;
      }

      button {
        border: none;
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: transparent;
        -webkit-text-fill-color: #e4e4e4;
        transition-duration: 0.3s;
        height: 66px;
        margin-bottom: 0px;
      }

      button:hover,
      button.active {
        background: #d6ad5a;
        -webkit-text-stroke-color: #fff;
        -webkit-text-fill-color: #fff;
      }
    }
  }

  div.tab {
    div.vertical-line {
      width: 2px;
      background: linear-gradient(
        180deg,
        #6a6969 0%,
        rgba(45, 45, 45, 0) 118.59%,
        #666666 118.59%
      );
      height: 150px;
      position: absolute;
      top: 50px;
      z-index: -1;
      left: 18px;

      + div.vertical-line {
        top: 200px;
      }
    }

    div.item {
      > div:first-child {
        width: 14px;
        height: 14px;
        background: #cfb071;
        border-radius: 50%;
      }

      > div:last-child {
        width: calc(100% - 62px);
        height: 103px;
        background: #2d2d2d;
        border-radius: 23px;
        display: flex;
        align-items: center;

        p {
          color: #e4e4e4;
        }
      }

      + div.item {
        margin-top: 44px;
      }
    }
  }

  .contact {
    background: #131313;
    border-radius: 30px;

    a {
      border: 3px solid transparent;
      border-radius: 16px;
      word-wrap: break-word;
      transition-duration: 0.3s;

      svg {
        width: 24px;
      }

      span {
        width: calc(100% - 40px);
      }

      :hover {
        background: rgba(207, 176, 113, 0.12);
        border-color: #cfb071;
      }

      + a {
        margin-top: 10px;
      }
    }

    .contact-form {
      background: #2d2d2d;
      border-radius: 20px;

      .interest-contact {
        background: rgba(207, 176, 113, 0.12);
        border: 3px solid #cfb071;
        color: #dec695;
        border-radius: 26px;
        transition-duration: 0.3s;
      }

      .interest-contact:hover,
      .interest-contact.selected {
        background: #cfb071;
        border-color: transparent;
        color: #654b16;
      }

      .input {
        border: none;
        border-bottom: 3px solid #656565 !important;
        border-radius: 0;
        background-color: transparent;

        input,
        textarea {
          ::placeholder {
            color: #707070;
          }
        }
      }
    }

    .social-media {
      a {
        height: 39px;
        width: 39px;
        background: #000000;
        margin: 0;

        :hover {
          background: rgba(0, 0, 0, 0.5);
          border-color: transparent;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    div.rooms {
      > div {
        width: 203.88818359375px;
        height: 432.1346740722656px;
      }

      .room-1,
      .room-3 {
        span {
          width: 66.75984954833984px;
          height: 66.75984954833984px;
          border-radius: 24px;

          svg {
            width: 38.15px;
            height: 38.15px;
          }
        }
      }

      .room-1 {
        span {
          left: -20.75px;
          bottom: 1px;
        }
      }

      .room-3 {
        span {
          right: 31.58px;
          top: -20.75px;
        }
      }
    }

    div.tabs {
      overflow: hidden;

      > div {
        button:first-child {
          border-radius: 24px 0px 0px 0px;
        }

        button:last-child {
          border-radius: 0px 24px 0px 0px;
        }
      }

      :hover {
        overflow: auto;
      }
    }
  }
`;

export const Hero = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    linear-gradient(
      71.79deg,
      rgba(0, 0, 0, 0.6) 37.87%,
      rgba(0, 0, 0, 0) 98.08%
    ),
    url(${bannerMobile}), #ffffff;
  background-size: cover;
  background-position: center;
  height: calc(100vh - 79px);

  @media screen and (min-width: 992px) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      linear-gradient(
        71.79deg,
        rgba(0, 0, 0, 0.6) 37.87%,
        rgba(0, 0, 0, 0) 98.08%
      ),
      url(${bannerDesktop});
    background-size: cover;
    background-position: center;
  }
`;
