import styled from 'styled-components';

interface ICheckboxInput {
  selected?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const CheckboxInput = styled.label<ICheckboxInput>`
  display: flex;
  align-items: start;

  span {
    color: #929292;
    font-weight: 500;
    width: calc(100% - 16px);
    display: block;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    margin-right: 8px;
    transition-duration: 0.3s;
    background: ${(props) =>
      props.selected ? '#cfb071' : 'rgba(207, 176, 113, 0.24)'};
    border: 1px solid #cfb071;
    margin-top: 5px;
  }

  .inputData {
    display: none;
  }

  + label {
    margin-left: 20px;
  }
`;

export const Error = styled.span``;
