import styled from 'styled-components';

interface IContainer {
  show: boolean;
}

export const Container = styled.div<IContainer>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1100;
`;
