import styled, { css } from 'styled-components';

interface ContainerProps {
  isFilled: boolean;
  isFocuses: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  border-radius: 10px;
  border: 1px solid #d3d3d3;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 44px;

  + div {
    margin-top: 8px;
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;

    .button-show {
      width: 70px;
      font-size: 12px;
      text-align: center;
      background: none;
      border: 0;
      padding: 6px 6px 6px;
      color: #2e90fd !important;
      height: 52px;
      font-weight: bold;
    }
  }

  input {
    width: 100%;
    flex: 1;
    background: transparent;
    border: 0;
    color: #fff;
    transition-duration: 0.2s;

    ::placeholder {
      color: #929292;
    }

    :focus {
      border: none;
      outline: 0;
    }
  }

  svg {
    margin-right: 16px;
    color: #8692a6;
    transition-duration: 0.2s;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) => props.isFilled && css``}

  ${(props) =>
    props.isFocuses &&
    css`
      color: #606060;
      border-color: #606060;
    `}
`;
