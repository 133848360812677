import React, { useCallback, useEffect, useRef, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { VscArrowSmallRight } from 'react-icons/vsc';
import { GiBrazil } from 'react-icons/gi';
import { TiStarFullOutline } from 'react-icons/ti';
import { IoMdPeople } from 'react-icons/io';
import { BsFillEnvelopeFill, BsFillTelephoneFill } from 'react-icons/bs';
import { RiMapPin2Fill, RiInstagramLine } from 'react-icons/ri';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';
import { useHistory, useLocation } from 'react-router-dom';
import { ImLinkedin2 } from 'react-icons/im';
import { SiWhatsapp } from 'react-icons/si';
import ReactGA from 'react-ga';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container, Hero } from './styles';
import Header from '~/components/Header';
import Input from '~/components/Input';
import Footer from '~/components/Footer';
import Textarea from '~/components/Textarea';
import InputCheckbox from '~/components/InputCheckbox';
import Loading from '~/components/Loading';

import stampLogo from '~/assets/logos/stamp-logo.svg';
import architectureInteriorDesign from '~/assets/defaults/architecture-interior-design.png';
import installationDesign from '~/assets/defaults/installation-design.png';
import structuralDesign from '~/assets/defaults/structural-design.png';
import budgetingPlanning from '~/assets/defaults/budgeting-planning.png';
import bim from '~/assets/defaults/bim.png';

interface IFormData {
  name: string;
  email: string;
  message: string;
  robot: string;
}

const Home: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [acceptError, setAcceptError] = useState('');
  const [accept, setAccept] = useState(false);
  const [interest, setInterest] = useState('Projeto');
  const [loading, setLoading] = useState(false);
  const [tabSelected, setTabSelected] = useState('Arquitetura e Interiores');

  useEffect(() => {
    if (location.pathname === '/solicitacao-recebida') {
      ReactGA.event({
        category: 'Conversão',
        action: 'SolicitacaoRecebida',
        label: 'AW-11191533268/F5NpCOH0qaQYENT9xNgp',
      });
    }
  }, [location.pathname]);

  const handleChangeAccept = useCallback((values) => {
    setAccept(values[0].selected);
  }, []);

  const handleClickInterest = useCallback((optionSelected) => {
    setInterest(optionSelected);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      setAcceptError('');
      setLoading(true);
      try {
        if (!data.robot) {
          formRef.current?.setErrors({});
          const schema = Yup.object().shape({
            name: Yup.string().required('O nome é obrigatório'),
            email: Yup.string()
              .email('Informe um e-mail válido')
              .required('O e-mail é obrigatório'),
            message: Yup.string().optional(),
            accept: Yup.string().when('$accept', {
              is: (acceptCheck: boolean) => acceptCheck,
              then: Yup.string().required('O aceite é obrigatório'),
              otherwise: Yup.string(),
            }),
          });

          await schema.validate(data, {
            abortEarly: false,
            context: {
              accept: !accept,
            },
          });

          const formData = {
            interest,
            name: data.name,
            email: data.email.toLowerCase(),
            message: data.message,
            accept: true,
          };

          await api.post('leads', formData);
        }

        history.push(`${process.env.PUBLIC_URL}/solicitacao-recebida`);
        Swal.fire('Tudo certo!', 'Contato salvo com sucesso.', 'success')
          .then(() => {
            history.push(`${process.env.PUBLIC_URL}`);
          })
          .then(() => {
            formRef.current?.reset();
          });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          if (errors.accept) {
            setAcceptError(errors.accept);
          }
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', 'Ocorreu um erro, tente novamente.', 'error');
        }
      } finally {
        setLoading(false);
      }
    },
    [accept, history, interest]
  );

  const handleSelectTab = useCallback((tab) => {
    setTabSelected(tab);
  }, []);

  return (
    <>
      <Container>
        <Header />
        <Hero id="home" className="pb-lg-5">
          <div className="container h-100 pb-lg-5">
            <div className="row align-items-center align-items-lg-end justify-content-center justify-content-lg-start pb-lg-5 h-100">
              <div className="col-11 col-lg-10">
                <h1 className="text-center text-lg-start h5 h3-lg text-primary fw-normal pe-lg-5">
                  DUO ULTIMATE{' '}
                  <span className="h2 display-lg-4 d-block fw-semibold my-4">
                    Nós da DUO ULTIMATE acreditamos que Projetos e Orçamentos
                    não são um problema e sim uma{' '}
                    <span className="text-secondary">SOLUÇÃO</span>
                  </span>
                </h1>
                <div className="d-flex justify-content-center justify-content-lg-start">
                  <AnchorLink
                    href="#contato"
                    offset={60}
                    className="h3 d-dlex justify-content-center mt-2 text-primary"
                  >
                    Contate-nos <VscArrowSmallRight size={47} color="#fff" />
                  </AnchorLink>
                </div>
              </div>
              <div className="col-lg-2 d-none d-lg-block align-self-start pt-5">
                <img
                  src={stampLogo}
                  alt="stamp logo"
                  className="d-block ms-auto"
                />
              </div>
            </div>
          </div>
        </Hero>
        <div className="container">
          <div
            id="quem-somos"
            className="row justify-content-center justify-content-lg-between py-5"
          >
            <div className="col-11  col-md-6 col-lg-5 col-xl-4 order-1 order-md-0 pe-lg-0">
              <h2 className="h4 text-secondary fw-medium">Quem somos?</h2>
              <h3 className="h2 text-primary fw-semibold my-5">
                A DUO ULTIMATE
              </h3>
              <p>
                É uma empresa que une Arquitetura e Engenharia utilizando
                tecnologia, inovação e informação para oferecer soluções em
                Projetos e Orçamentos utilizando o BIM (Building Information
                Modeling).
              </p>
              <p>
                Desenvolvemos soluções integradas e inteligentes para entregar
                ao cliente projetos e orçamentos já compatibilizados, detalhados
                com todas as informações para a plena execução da obra, com
                máxima qualidade, abraçando a necessidade individual de cada
                cliente e obra, entregando soluções personalizadas.
              </p>
              <div className="d-flex flex-wrap">
                <div className="d-flex tag px-3 py-2 rounded-pill me-3 my-2">
                  <GiBrazil size={24} color="#6B4D10" className="me-2" />
                  <p className="mb-0">Atendemos todo o Brasil</p>
                </div>
              </div>
            </div>
            <div className="col-11 col-md-6 order-0 order-md-1 rooms d-flex flex-md-column flex-lg-row justify-content-between justify-content-lg-end pb-5 pb-lg-0">
              <div className="room-1 align-self-md-end align-self-lg-start">
                <span>
                  <TiStarFullOutline size={22} color="#CFB071" />
                </span>
              </div>
              <div className="room-2 mx-2 mx-md-0 mx-lg-3 mx-xl-2 mb-md-n5 mt-md-n5 mt-lg-5 mt-xxl-0 mb-lg-0 align-self-md-center align-self-lg-start" />
              <div className="room-3">
                <span>
                  <IoMdPeople size={22} color="#CFB071" />
                </span>
              </div>
            </div>
          </div>
          <div id="nossas-solucoes" className="row justify-content-center py-5">
            <div className="col-11 col-md-12 mb-4">
              <div className="row">
                <div className="col-lg-6 col-xl-4 pe-xl-0">
                  <h2 className="h4 text-secondary fw-medium">
                    Nossas soluções
                  </h2>
                  <h3 className="h2 text-primary fw-semibold my-4">
                    Conheça nossas soluções
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-12 tabs">
              <div className="d-flex flex-column flex-lg-row w-100">
                <button
                  type="button"
                  onClick={() => handleSelectTab('Arquitetura e Interiores')}
                  className={`flex-lg-fill h5 fw-normal px-4 ${
                    tabSelected === 'Arquitetura e Interiores' ? 'active' : ''
                  }`}
                >
                  Arquitetura e Interiores
                </button>
                <button
                  type="button"
                  onClick={() => handleSelectTab('Estrutura')}
                  className={`flex-lg-fill h5 fw-normal px-4 ${
                    tabSelected === 'Estrutura' ? 'active' : ''
                  }`}
                >
                  Estrutura
                </button>
                <button
                  type="button"
                  onClick={() => handleSelectTab('Instalações')}
                  className={`flex-lg-fill h5 fw-normal px-4 ${
                    tabSelected === 'Instalações' ? 'active' : ''
                  }`}
                >
                  Instalações
                </button>
                <button
                  type="button"
                  onClick={() => handleSelectTab('Orçamento e Planejamento')}
                  className={`flex-lg-fill h5 fw-normal px-4 ${
                    tabSelected === 'Orçamento e Planejamento' ? 'active' : ''
                  }`}
                >
                  Orçamento e Planejamento
                </button>
                <button
                  type="button"
                  onClick={() => handleSelectTab('BIM')}
                  className={`flex-lg-fill h5 fw-normal px-4 ${
                    tabSelected === 'BIM' ? 'active' : ''
                  }`}
                >
                  BIM
                </button>
              </div>
            </div>
            {tabSelected === 'Arquitetura e Interiores' && (
              <div className="col-12 mt-5">
                <div className="row justify-content-between align-items-center tab">
                  <div className="col-lg-8 position-relative order-1 order-lg-0">
                    <div className="vertical-line" />
                    <div className="d-flex item justify-content-between align-items-center">
                      <div />
                      <div className="px-5">
                        <p className="mb-0 h5 h4-sm fw-normal">
                          Projeto Arquitetônico
                        </p>
                      </div>
                    </div>
                    <div className="d-flex item justify-content-between align-items-center">
                      <div />
                      <div className="px-5">
                        <p className="mb-0 h5 h4-sm fw-normal">
                          Projeto de Interiores
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-5 mb-lg-0 order-0 order-lg-1">
                    <img
                      src={architectureInteriorDesign}
                      alt="Arquitetura e Interiores"
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
            )}
            {tabSelected === 'Estrutura' && (
              <div className="col-12 mt-5">
                <div className="row justify-content-between align-items-center tab">
                  <div className="col-lg-8 position-relative order-1 order-lg-0">
                    <div className="vertical-line" />
                    <div className="d-flex item justify-content-between align-items-center">
                      <div />
                      <div className="px-5">
                        <p className="mb-0 h5 h4-sm fw-normal">
                          Projetos de Fundação
                        </p>
                      </div>
                    </div>
                    <div className="d-flex item justify-content-between align-items-center">
                      <div />
                      <div className="px-5">
                        <p className="mb-0 h5 h4-sm fw-normal">
                          Projetos de Superestrutura
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-5 mb-lg-0 order-0 order-lg-1">
                    <img
                      src={structuralDesign}
                      alt="Estrutura"
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
            )}
            {tabSelected === 'Instalações' && (
              <div className="col-12 mt-5">
                <div className="row justify-content-between align-items-center tab">
                  <div className="col-lg-8 position-relative order-1 order-lg-0">
                    <div className="vertical-line" />
                    <div className="d-flex item justify-content-between align-items-center">
                      <div />
                      <div className="px-5">
                        <p className="mb-0 h5 h4-sm fw-normal">
                          Projeto de Instalações Hidrossanitárias
                        </p>
                      </div>
                    </div>
                    <div className="d-flex item justify-content-between align-items-center">
                      <div />
                      <div className="px-5">
                        <p className="mb-0 h5 h4-sm fw-normal">
                          Projeto de Instalações Elétricas e Telecomunicações
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-5 mb-lg-0 order-0 order-lg-1">
                    <img
                      src={installationDesign}
                      alt="Instalações"
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
            )}
            {tabSelected === 'Orçamento e Planejamento' && (
              <div className="col-12 mt-5">
                <div className="row justify-content-between align-items-center tab">
                  <div className="col-lg-8 position-relative order-1 order-lg-0">
                    <div className="vertical-line" />
                    <div className="d-flex item justify-content-between align-items-center">
                      <div />
                      <div className="px-5">
                        <p className="mb-0 h5 h4-sm fw-normal">
                          Orçamento de obras | BIM 5D
                        </p>
                      </div>
                    </div>
                    <div className="d-flex item justify-content-between align-items-center">
                      <div />
                      <div className="px-5">
                        <p className="mb-0 h5 h4-sm fw-normal">
                          Planejamento de obras | BIM 4D
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-5 mb-lg-0 order-0 order-lg-1">
                    <img
                      src={budgetingPlanning}
                      alt="Orçamento e Planejamento"
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
            )}
            {tabSelected === 'BIM' && (
              <div className="col-12 mt-5">
                <div className="row justify-content-between align-items-center tab">
                  <div className="col-lg-8 position-relative order-1 order-lg-0">
                    <div className="vertical-line" />
                    <div className="vertical-line" />
                    <div className="d-flex item justify-content-between align-items-center">
                      <div />
                      <div className="px-5">
                        <p className="mb-0 h5 h4-sm fw-normal">Modelagem BIM</p>
                      </div>
                    </div>
                    <div className="d-flex item justify-content-between align-items-center">
                      <div />
                      <div className="px-5">
                        <p className="mb-0 h5 h4-sm fw-normal">
                          Consultoria & Implementação BIM
                        </p>
                      </div>
                    </div>
                    <div className="d-flex item justify-content-between align-items-center">
                      <div />
                      <div className="px-5">
                        <p className="mb-0 h5 h4-sm fw-normal">
                          Treinamentos BIM
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-5 mb-lg-0 order-0 order-lg-1">
                    <img src={bim} alt="BIM" className="w-100" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div id="contato" className="row justify-content-center py-5">
            <div className="col-11 col-md-12">
              <div className="row px-3 py-4 p-md-5 contact">
                <div className="col-lg-5 col-xl-6 pe-lg-0 pe-xl-5">
                  <h2 className="h4 text-secondary fw-medium">Contato</h2>
                  <h3 className="h2 text-primary fw-semibold mt-4">
                    Entre em <br />
                    contato conosco
                  </h3>
                  <div className="my-5 pt-lg-4 me-lg-5 pe-xl-5">
                    <a
                      href="mailto:contato@duoultimate.com.br"
                      className="d-flex p-3 align-items-center"
                    >
                      <BsFillEnvelopeFill
                        size={24}
                        color="#CFB071"
                        className="me-3"
                      />
                      <span className="text-primary">
                        contato@duoultimate.com.br
                      </span>
                    </a>
                    <a
                      href="tel:+5581982306464"
                      className="d-flex p-3 align-items-center"
                    >
                      <BsFillTelephoneFill
                        size={24}
                        color="#CFB071"
                        className="me-3"
                      />
                      <span className="text-primary">+55 (81) 98230-6464</span>
                    </a>
                    <a
                      href="https://www.google.com.br/maps/place/R.+Lu%C3%ADs+de+Farias+Barbosa,+120+-+Sala+5+-+Boa+Viagem,+Recife+-+PE,+51020-110/@-8.1092141,-34.8995881,17z/data=!3m1!4b1!4m6!3m5!1s0x7ab1f07bb9bffff:0xcb331a066b2f3533!8m2!3d-8.1092141!4d-34.8973941!16s%2Fg%2F11llbstdkd"
                      className="d-flex p-3 align-items-center"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <RiMapPin2Fill
                        size={24}
                        color="#CFB071"
                        className="me-3"
                      />
                      <span className="text-primary">
                        R. Luís de Farias Barbosa, 120 - Sala 5 - Boa Viagem,
                        Recife - PE, 51020-110
                      </span>
                    </a>
                  </div>
                  <div className="d-flex mb-3 social-media ps-3 pe-xl-5">
                    <a
                      href="https://www.linkedin.com/company/duoultimate"
                      className="d-flex align-items-center justify-content-center rounded-circle"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ImLinkedin2 size={19.5} color="#CFB071" />
                    </a>
                    <a
                      href="https://api.whatsapp.com/send?phone=5581982306464&text=Ol%C3%A1,%20estou%20vindo%20do%20site%20da%20Duo%20Ultimate%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es"
                      className="d-flex align-items-center justify-content-center rounded-circle mx-3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SiWhatsapp size={19.5} color="#CFB071" />
                    </a>
                    <a
                      href="https://www.instagram.com/duo.ultimate"
                      className="d-flex align-items-center justify-content-center rounded-circle"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <RiInstagramLine size={19.5} color="#CFB071" />
                    </a>
                  </div>
                </div>
                <div className="col-lg-7 col-xl-6 mt-lg-0">
                  <div className="contact-form px-3 py-4 p-md-5">
                    <p className="text-primary mb-4 mt-2">
                      Estou interessado em:
                    </p>
                    <div className="d-flex flex-column flex-md-row align-items-md-center">
                      <button
                        type="button"
                        className={`interest-contact py-1 px-md-4 mb-3 mb-md-0 me-md-3 ${
                          interest === 'Projeto' ? 'selected' : ''
                        }`}
                        onClick={() => handleClickInterest('Projeto')}
                      >
                        Projeto
                      </button>
                      <button
                        type="button"
                        className={`interest-contact py-1 px-md-4 mb-3 mb-md-0 me-md-3 ${
                          interest === 'Orçamento' ? 'selected' : ''
                        }`}
                        onClick={() => handleClickInterest('Orçamento')}
                      >
                        Orçamento
                      </button>
                      <button
                        type="button"
                        className={`interest-contact py-1 px-md-4 ${
                          interest === 'Consultoria BIM' ? 'selected' : ''
                        }`}
                        onClick={() => handleClickInterest('Consultoria BIM')}
                      >
                        Consultoria BIM
                      </button>
                    </div>
                    <Form
                      ref={formRef}
                      onSubmit={handleSubmit}
                      className="pt-5 pb-3"
                    >
                      <label className="d-block mb-3">
                        <Input name="robot" className="d-none" />
                        <Input
                          name="name"
                          className="input w-100"
                          placeholder="Seu nome"
                        />
                      </label>
                      <label className="d-block mb-3">
                        <Input
                          type="email"
                          name="email"
                          className="input w-100"
                          placeholder="Seu melhor e-mail"
                        />
                      </label>
                      <label className="d-block mb-5">
                        <Textarea
                          name="message"
                          rows={4}
                          className="input w-100"
                          placeholder="Sua mensagem (Opcional)"
                        />
                      </label>
                      <InputCheckbox
                        name="accept"
                        options={[
                          {
                            label:
                              'Aceito compartilhar meus dados afim de receber contato da empresa.',
                            value: 1,
                          },
                        ]}
                        className="input-checkbox mt-3"
                        error={acceptError}
                        onChange={handleChangeAccept}
                      />
                      <button
                        type="submit"
                        className="bg-transparent border-0 w-100 d-flex align-items-center justify-content-center justify-content-md-end"
                      >
                        <span className="h4 text-primary mb-0 me-2">
                          Enviar
                        </span>
                        <VscArrowSmallRight size={47} color="#fff" />
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Loading show={loading} />
      </Container>
    </>
  );
};

export default Home;
